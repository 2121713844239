import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  TooltipProps
} from 'recharts';
import moment from 'moment';

interface TradeData {
  time: string;
  capital: number;
  previousCapital?: number;
}

interface CapitalChangeChartProps {
  tradeData: TradeData[];
}

const generateContinuousData = (tradeData: TradeData[]) => {
  if (tradeData.length === 0) return [];

  const sortedData = [...tradeData].sort((a, b) => moment(a.time).diff(moment(b.time)));

  const data: TradeData[] = [];
  let lastCapital = sortedData[0].capital;
  let previousCapital = 0; // Initial value for the first entry's previous capital

  for (let m = moment(sortedData[0].time); m.diff(moment(sortedData[sortedData.length - 1].time), 'days') <= 0; m.add(1, 'days')) {
    const existingTrade = sortedData.find(trade => moment(trade.time).format("YYYY-MM-DD") === m.format("YYYY-MM-DD"));
    if (existingTrade) {
      data.push({
        ...existingTrade,
        previousCapital: previousCapital
      });
      previousCapital = lastCapital; // Update previous capital to current before changing current
      lastCapital = existingTrade.capital;
    } else {
      data.push({
        time: m.format("YYYY-MM-DD"),
        capital: lastCapital,
        previousCapital: previousCapital
      });
    }
  }

  return data.map(({ time, capital, previousCapital }) => ({
    name: time,
    capital: Math.round(capital),
    previousCapital
  }));
};


// Helper function to calculate percentage change
const calculatePercentageChange = (current: number, previous: number) => {
  if (previous === 0) return 'N/A'; // handle division by zero or no previous data
  const change = ((current - previous) / previous) * 100;
  return change.toFixed(2) + '%';
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const currentCapital = payload[0].value || 0;
    const previousCapital = payload[0].payload.previousCapital || 0; // Ensure your data has 'previousCapital'
    const percentageChange = calculatePercentageChange(currentCapital, previousCapital);

    return (
      <div style={{
        backgroundColor: '#000000',
        padding: '10px',
        border: '1px solid #FFFFFF',
        color: 'white'
      }}>
        <p>{`Date: ${label.split('T')[0]}`}</p> {/* Assuming the label includes time in ISO format */}
        <p>{`Capital: ${currentCapital.toLocaleString()}`}</p>
        <p>{`Change: ${percentageChange}`}</p>
      </div>
    );
  }

  return null;
};

const CapitalChangeChart: React.FC<CapitalChangeChartProps> = ({ tradeData }) => {
  const data = generateContinuousData(tradeData);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis domain={['auto', 'auto']} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {data.map((entry, index) => {
          const isFirstOfMonth = moment(entry.name).date() === 1;
          return isFirstOfMonth ? (
            <ReferenceLine key={index} x={entry.name} stroke="grey" strokeDasharray="3 3" />
          ) : null;
        })}
        <Line type="monotone" dataKey="capital" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default CapitalChangeChart;
