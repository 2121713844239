import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Table, Spin, Alert, Select, Row, Col, Card, Statistic, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TradeCycleComponent from '../components/TradeCycleComponent';
import BalanceGraph from '../components/BalanceGraph';


interface CoinInfo {
  coin: string;
  usdBalance: number;
  pctBalance: number;
  qtyBalance: number;
  currentPrice: number;
}

interface AccountInfo {
  accountInfo: CoinInfo[];
  customerName?: string; // Optional to display customer name if needed
  tradeCycles: any;
}

interface CapitalsInfo {
  starting: number;
  yearly: number;
  monthly: number;
  weekly: number;
}

const fetchFeeInfo = async (customerName: string) => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/fee-info/?customer_name=${customerName}`, {
    headers: new Headers({
        'Authorization': `Token ${authToken}`,
    }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchCapitals = async (customerName: string): Promise<CapitalsInfo> => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/capital/${customerName}/`, {
    headers: new Headers({
        'Authorization': `Token ${authToken}`,
    }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const fetchAccountInfo = async (customerName: string): Promise<AccountInfo> => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/accountInfo/?customer_name=${customerName}`, {
    headers: new Headers({
        'Authorization': `Token ${authToken}`,
    }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

const AccountInfoPage: React.FC = () => {
  const [customerName, setCustomerName] = useState('Zac');
  
  // State to control the visibility of the table
  const [isTableVisible, setIsTableVisible] = useState(false);

  // Function to toggle the table visibility
  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  const { data: tradingInfo, error: tradingInfoError, isLoading: isLoadingTradingInfo } = useQuery(
    ['tradingInfo', customerName],
    () => fetchFeeInfo(customerName),
    { enabled: !!customerName }
  );

  const { data: capitals, error: capitalsError, isLoading: isLoadingCapitals } = useQuery(
    ['capitals', customerName], 
    () => fetchCapitals(customerName), 
    { enabled: !!customerName } // This query runs only if customerName is not empty
  );
  
  const { data, error, isLoading, refetch } = useQuery<AccountInfo, Error>(
    ['accountInfo', customerName],
    () => fetchAccountInfo(customerName),
    { 
      refetchInterval: 60000 * 5, // Refetch data every 60000 * 5 milliseconds (5 minutes)
      refetchIntervalInBackground: true,
    }
  );
  
  useEffect(() => {
    refetch(); // Refetch data when customerName changes
  }, [customerName, refetch]);
  
  const calculatePercentageChange = (coin: string, currentPrice: number) => {
    const activeTradeCycle = data?.tradeCycles.find((cycle: any) => cycle.coin === coin && cycle.end_time === null);
    if (activeTradeCycle) {
      const actualAveragePrice = parseFloat(activeTradeCycle.trades[0].actual_average_price);
      return ((currentPrice - actualAveragePrice) / actualAveragePrice) * 100;
    }
    return null; // No active trade cycle
  };

  const columns: ColumnsType<CoinInfo> = [
    {
      title: 'Coin',
      dataIndex: 'coin',
      key: 'coin',
    },
    {
      title: 'USD Balance',
      dataIndex: 'usdBalance',
      key: 'usdBalance',
      render: (value) => `$${value.toLocaleString()}`,
    },
    {
      title: 'Balance %',
      dataIndex: 'pctBalance',
      key: 'pctBalance',
      render: (value) => `${value}%`,
    },
    {
      title: 'Quantity',
      dataIndex: 'qtyBalance',
      key: 'qtyBalance',
      render: (text, record) => record.coin === 'USD' ? '' : parseFloat(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    {
      title: 'Current Price',
      dataIndex: 'currentPrice',
      key: 'currentPrice',
      render: (value, record) => record.coin === 'USD' ? '' : `$${value.toLocaleString()}`,
    },
    {
      title: 'Change',
      key: 'change',
      render: (_, record) => {
        const percentageChange = calculatePercentageChange(record.coin, record.currentPrice);
        return percentageChange !== null ? `${percentageChange.toFixed(2)}%` : '';
      },
    },
  ];

  if (!capitals) return <Spin size="large" tip="Loading capitals..." />;

  // Filter out rows with zero USD balance
  const filteredData = data?.accountInfo.filter(coin => coin.usdBalance > 0);

  const activeTradeCycle = data?.tradeCycles.find((cycle: any) => cycle.end_time === null);
  const activeTrade = activeTradeCycle?.trades[0];
  const activeCoinInfo = data?.accountInfo.find((coin: CoinInfo) => coin.coin === activeTradeCycle?.coin);
  const percentageChange = activeTrade && activeCoinInfo?.currentPrice !== undefined ? ((activeCoinInfo.currentPrice - parseFloat(activeTrade.actual_average_price)) / parseFloat(activeTrade.actual_average_price)) * 100 : 0;
  const dollarChange = activeTrade && activeCoinInfo?.currentPrice !== undefined ? (activeCoinInfo.currentPrice - parseFloat(activeTrade.actual_average_price)) * parseFloat(activeTrade.final_total_quantity) : 0;
  const totalBalance = data?.accountInfo.reduce((acc, coin) => acc + coin.usdBalance, 0) || 0;

  // Calculate percentage changes
  const overallChange = ((totalBalance - capitals.starting) / capitals.starting) * 100;
  const yearChange = ((totalBalance - capitals.yearly) / capitals.yearly) * 100;
  const monthChange = ((totalBalance - capitals.monthly) / capitals.monthly) * 100;
  const weekChange = ((totalBalance - capitals.weekly) / capitals.weekly) * 100;

  if (isLoadingTradingInfo) return <Spin size="large" tip="Loading trading information..." />;
  if (isLoading) return <Spin style={{ padding: '100px' }} size='large' tip="Loading..." />;
  if (error) return <Alert message="Error" description={error.message} type="error" showIcon />;

  return (
    <div style={{ padding: '20px' }}>
      <Select defaultValue="Zac" value={customerName} style={{ width: 120, marginBottom: 20 }} onChange={(value) => setCustomerName(value)}>
        <Select.Option value="Zac">Zac</Select.Option>
      </Select>
      
      {data && (
        <>
          <Row gutter={16}>
            <Col span={5}>
              <Card>
                <Statistic 
                  title="Total Balance" 
                  value={totalBalance.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })} 
                />
                </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic title="Current Trade" value={activeCoinInfo ? activeCoinInfo.coin : 'NONE'} />
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic
                  title="$ Change"
                  value={dollarChange.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  valueStyle={{ color: dollarChange >= 0 ? '#52c41a' : '#ff4d4f' }}
                />
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Statistic title="% Change" value={percentageChange} precision={2} suffix="%" valueStyle={{ color: percentageChange >= 0 ? '#52c41a' : '#ff4d4f' }} />
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic 
                  title="30 Day Trading Volume"
                  value={new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(tradingInfo.total_volume)}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col span={5}>
              <Card>
                <Statistic title="Overall Change" value={overallChange.toFixed(2)} suffix="%" valueStyle={{ color: overallChange >= 0 ? '#52c41a' : '#ff4d4f' }} />
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic title="Yearly Change" value={yearChange.toFixed(2)} suffix="%" valueStyle={{ color: yearChange >= 0 ? '#52c41a' : '#ff4d4f' }} />
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic title="Monthly Change" value={monthChange.toFixed(2)} suffix="%" valueStyle={{ color: monthChange >= 0 ? '#52c41a' : '#ff4d4f' }} />
              </Card>
            </Col>
            <Col span={4}>
              <Card>
                <Statistic title="Weekly Change" value={weekChange.toFixed(2)} suffix="%" valueStyle={{ color: weekChange >= 0 ? '#52c41a' : '#ff4d4f' }} />
              </Card>
            </Col>
            <Col span={5}>
              <Card>
                <Statistic
                  title="Current Maker Fee"
                  value={`${(tradingInfo.fee_tier.maker_fee_rate * 100).toFixed(2)}%`}
                />
              </Card>
            </Col>
          </Row>
          <br></br>
          {/* Toggle Button for the Table */}
          <Button type="primary" onClick={toggleTableVisibility} style={{ marginBottom: '10px' }}>
            {isTableVisible ? 'Hide' : 'Show'} Balances and Chart
          </Button>

          {/* Conditional Rendering of the Table based on isTableVisible */}
          {isTableVisible && (
            <Table style={{ marginBottom: '20px' }} size='small' columns={columns} dataSource={filteredData} rowKey="coin" pagination={false} />
          )}
          {isTableVisible && (
            <BalanceGraph customerName={customerName} />
          )}
          <TradeCycleComponent tradeCycles={data?.tradeCycles} />
        </>
      )}
    </div>
  );
};

export default AccountInfoPage;
