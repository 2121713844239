import React from "react";
import { Table, Row, Col, Statistic } from 'antd';
import moment from "moment";

interface MonthlySummaryMetrics {
  [monthYear: string]: {
    avgActualPct: number;
    avgBestActualPct: number;
    avgBestActualPctByThreshold: Record<number, number>;
    endingCapitalByThreshold: Record<number, number>;
    tradeCountsByThreshold: Record<number, number>;
    avgRankByThreshold: Record<string, number>;
  };
}

interface MonthlySummaryMetricsDisplayProps {
  monthlySummary: MonthlySummaryMetrics;
  initialCapital: number;
  threshold: number;
}

const MonthlySummaryMetricsDisplay: React.FC<MonthlySummaryMetricsDisplayProps> = ({ monthlySummary, initialCapital, threshold }) => {
    const dataSource = Object.keys(monthlySummary).map(monthYear => {
      const metrics = monthlySummary[monthYear];
    
      const percentGain = ((metrics.endingCapitalByThreshold[threshold] - initialCapital) / initialCapital) * 100;
      const avgRank = metrics.avgRankByThreshold[threshold]; // Assume you have calculated this somehow
    
      return {
        key: monthYear,
        monthYear,
        avgActualPct: `${(metrics.avgActualPct * 100)?.toFixed(2)}%`,
        avgBestActualPctForThreshold: `${(metrics.avgBestActualPctByThreshold[threshold] * 100)?.toFixed(2)}%`,
        avgActualPctDiff: `${((metrics.avgBestActualPctByThreshold[threshold] - metrics.avgActualPct) * 100)?.toFixed(2)}%`,
        endingCapitalForThreshold: (metrics.endingCapitalByThreshold[threshold] - initialCapital)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
        tradeCountForThreshold: metrics.tradeCountsByThreshold[threshold],
        percentGain: percentGain.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        avgRank: avgRank?.toFixed(2) // Include average rank here
      };
    }).sort((a, b) => new Date(a.monthYear).getTime() - new Date(b.monthYear).getTime());
    
    const totalAvgActualPct = dataSource.reduce((acc, curr) => acc + parseFloat(curr.avgActualPct.replace('%', '')), 0);
    const totalAvgBestActualPctForThreshold = dataSource.reduce((acc, curr) => acc + parseFloat(curr.avgBestActualPctForThreshold.replace('%', '')), 0);
    const totalAvgActualPctDiff = dataSource.reduce((acc, curr) => acc + parseFloat(curr.avgActualPctDiff.replace('%', '')), 0);
    const totalEndingCapitalForThreshold = dataSource.reduce((acc, curr) => acc + parseFloat(curr.endingCapitalForThreshold.replace(/[$,]/g, '')), 0);

    const averageAvgActualPct = totalAvgActualPct / dataSource.length;
    const averageAvgBestActualPctForThreshold = totalAvgBestActualPctForThreshold / dataSource.length;
    const averageAvgActualPctDiff = totalAvgActualPctDiff / dataSource.length;
    const averageEndingCapitalForThreshold = totalEndingCapitalForThreshold / dataSource.length;

    const totalTradeCount = dataSource.reduce((acc, curr) => acc + curr.tradeCountForThreshold, 0);
    const totalPercentGain = dataSource.reduce((acc, curr) => acc + parseFloat(curr.percentGain), 0);

    const averageTradeCount = totalTradeCount / dataSource.length;
    const averagePercentGain = totalPercentGain / dataSource.length;

    const averageWeeksInMonth = 4.345;
    const averagePercentGainPerWeek = averagePercentGain / averageWeeksInMonth;
    
    const compoundedWeeklyRate = Math.pow(1 + averagePercentGain / 100, 1 / averageWeeksInMonth) - 1;
    const averagePercentGainPerWeekCompounded = compoundedWeeklyRate * 100;

    const columns = [
      {
        title: 'Month',
        dataIndex: 'monthYear',
        key: 'monthYear',
        sorter: (a: any, b: any) => moment(a.monthYear, "YYYY-MM").diff(moment(b.monthYear, "YYYY-MM")),
        // render: text => moment(text).format("YYYY-MM"),
        // Adjust the format inside `render` and `sorter` to match the expected input format for weekly, monthly, quarterly, yearly
      },
      {
        title: 'Trade Count (2.8%)',
        dataIndex: 'tradeCountForThreshold',
        key: 'tradeCountForThreshold',
        align: 'right' as const,
        sorter: (a: any, b: any) => a.tradeCountForThreshold - b.tradeCountForThreshold,
      },
      {
        title: 'Mean All Coins',
        dataIndex: 'avgActualPct',
        key: 'avgActualPct',
        align: 'right' as const,
        sorter: (a: any, b: any) => parseFloat(a.avgActualPct) - parseFloat(b.avgActualPct),
      },
      {
        title: 'Mean Best Coin (2.8%)',
        dataIndex: 'avgBestActualPctForThreshold',
        key: 'avgBestActualPctForThreshold',
        align: 'right' as const,
        sorter: (a: any, b: any) => parseFloat(a.avgBestActualPctForThreshold) - parseFloat(b.avgBestActualPctForThreshold),
      },
      {
        title: 'Best - All (2.8%)',
        dataIndex: 'avgActualPctDiff',
        key: 'avgActualPctDiff',
        align: 'right' as const,
        sorter: (a: any, b: any) => parseFloat(a.avgActualPctDiff) - parseFloat(b.avgActualPctDiff),
      },
      {
        title: 'Capital Change (2.8%)',
        dataIndex: 'endingCapitalForThreshold',
        key: 'endingCapitalForThreshold',
        align: 'right' as const,
        sorter: (a: any, b: any) => parseFloat(a.endingCapitalForThreshold.replace(/[$,]/g, '')) - parseFloat(b.endingCapitalForThreshold.replace(/[$,]/g, '')),
      },
      {
        title: 'Change',
        dataIndex: 'percentGain',
        key: 'percentGain',
        render: (text: string) => `${text}%`,
        align: 'right' as const,
        sorter: (a: any, b: any) => parseFloat(a.percentGain) - parseFloat(b.percentGain),
      },
      {
        title: 'Avg Rank',
        dataIndex: 'avgRank',
        key: 'avgRank',
        align: 'right' as const,
        sorter: (a: any, b: any) => parseFloat(a.avgRank) - parseFloat(b.avgRank),
      },
    ];
  
    return (
        <div style={{ margin: '20px' }}>
            <Row gutter={16} style={{ marginTop: '20px', marginBottom: '20px' }}>
                <Col span={4}>
                    <Statistic title="Avg Trade Count (2.8%)" value={averageTradeCount.toFixed(2)} />
                </Col>
                <Col span={4}>
                    <Statistic title="Avg All Coins" value={`${averageAvgActualPct.toFixed(2)}%`} />
                </Col>
                <Col span={4}>
                    <Statistic title="Avg Best Coin (2.8%)" value={`${averageAvgBestActualPctForThreshold.toFixed(2)}%`} />
                </Col>
                <Col span={4}>
                    <Statistic title="Best - All (2.8%)" value={`${averageAvgActualPctDiff.toFixed(2)}%`} />
                </Col>
                <Col span={4}>
                    <Statistic title="Total Monthly (2.8%)" value={totalEndingCapitalForThreshold.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} />
                </Col>
                <Col span={4}>
                    <Statistic title="Avg Per Month (2.8%)" value={averageEndingCapitalForThreshold.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} />
                </Col>
                <Col span={4}>
                    <Statistic title="Avg % Change" value={`${averagePercentGain.toFixed(2)}%`} />
                </Col>
                <Col span={4}>
                    <Statistic title="Avg Weekly %" value={`${averagePercentGainPerWeek.toFixed(2)}%`} />
                </Col>
                <Col span={4}>
                    <Statistic title="Avg Weekly % (Compounded)" value={`${averagePercentGainPerWeekCompounded.toFixed(2)}%`} />
                </Col>
            </Row>
            <Table size='small' style={{ width: '1200px' }} dataSource={dataSource} columns={columns} pagination={false} />
        </div>
    );
};

export default MonthlySummaryMetricsDisplay;
