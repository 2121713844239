import React, { ReactNode, useState, useEffect } from 'react';
import { AuthContext } from './AuthContext';

interface AuthProviderProps {
    children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [authToken, setAuthToken] = useState<string | null>(localStorage.getItem('authToken'));
    const [username, setUsername] = useState<string | null>(localStorage.getItem('username'));
    const [name, setName] = useState<string | null>(localStorage.getItem('name'));
    const isLoggedIn = Boolean(authToken);

    useEffect(() => {
        setAuthToken(localStorage.getItem('authToken'));
        setUsername(localStorage.getItem('username'));
        setName(localStorage.getItem('name'));
    }, []);

    const login = (username: string, password: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            const backendUrl = process.env.REACT_APP_BACKEND_URL;
            fetch(`${backendUrl}api/api-token-auth/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password })
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Username/password don't match");
                }
            })
            .then(data => {
                if (data && data.token) {
                    localStorage.setItem('authToken', data.token);
                    localStorage.setItem('username', username);
                    setAuthToken(data.token);
                    setUsername(username); // update username state

                    resolve();
                } else {
                    reject(new Error("The response did not contain a token"));
                }
            })
            .catch(err => {
                reject(new Error("An error occurred while logging in: " + err.message));
            });
        });
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('username');
        localStorage.removeItem('name');
        setAuthToken(null);
        setUsername(null);
        setName(null);
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, username, name, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
