import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps } from 'recharts';
import moment from 'moment';
import { Spin, Alert, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface CapitalLog {
  customer: string;
  capital_amount: number;
  timestamp: string;
}

const fetchCapitalLogs = async (customerName: string): Promise<CapitalLog[]> => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/capital-logs/?customer_name=${customerName}`, {
    headers: new Headers({
        'Authorization': `Token ${authToken}`,
    }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

interface ExtendedCapitalLog extends CapitalLog {
  percentageChange: string; // Adding new property to original CapitalLog
}

const calculatePercentageChanges = (data: CapitalLog[]): ExtendedCapitalLog[] => {
  return data.map((log, index, arr) => {
    const previousLog = arr[index - 1];
    const percentageChange = previousLog ? ((log.capital_amount - previousLog.capital_amount) / previousLog.capital_amount) * 100 : 0;
    return {
      ...log,
      timestamp: moment(log.timestamp).format('MM/DD'), // Format date
      percentageChange: percentageChange.toFixed(2) // Format percentage
    };
  });
};


const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length > 0) {
    return (
      <div style={{
        backgroundColor: '#000',
        padding: '10px',
        border: '1px solid #fff',
        color: '#fff'
      }}>
        <p>Date: {label}</p> {/* Assume label is already formatted date */}
        <p>Capital: ${payload[0].value ? payload[0].value.toFixed(2) : 'N/A'}</p>
        <p>Change: {payload[0].payload.percentageChange}%</p>
      </div>
    );
  }
  return null;
};

const BalanceGraph: React.FC<{ customerName: string }> = ({ customerName }) => {
  const [showOnlyFirst, setShowOnlyFirst] = useState(false);
  const { data, error, isLoading } = useQuery<CapitalLog[], Error>(['capitalLogs', customerName], () => fetchCapitalLogs(customerName));

  if (isLoading) return <Spin size="large" />;
  if (error) return <Alert message="Error" description="Failed to load data" type="error" showIcon />;

  const handleToggle = (e: CheckboxChangeEvent) => {
    setShowOnlyFirst(e.target.checked);
  };

  const filteredData = showOnlyFirst
    ? data?.filter(log => moment(log.timestamp).date() === 1)
    : data;

  const filteredAndProcessedData = calculatePercentageChanges(filteredData ?? []);

  return (
    <div>
      <Checkbox checked={showOnlyFirst} onChange={handleToggle}>
        Show Only First of Each Month
      </Checkbox>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          data={filteredAndProcessedData ?? []}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" tickFormatter={(timestamp) => moment(timestamp).format('MM/DD')} />
          <YAxis domain={['auto', 'auto']} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line type="monotone" dataKey="capital_amount" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BalanceGraph;
