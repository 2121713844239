// hooks/useBacktestResults.ts
import { useQuery } from 'react-query';

export interface BacktestResult {
  // Define the structure based on your backend response
  last_candle_time: string;
  target_time: string;
  coin: string;
  actual_pct: number;
  pred_pct: number;
  model1_mean: number;
  model2_mean: number;
  model3_mean: number;
  model4_mean: number;
  model5_mean: number;
  model6_mean: number;
  avgModel124: number;
  isBest: boolean;
  dailyRank: number;
}

const fetchBacktestResults = async (filters: Record<string, string | string[]>) => {
  // Convert filters to query parameters, handling arrays correctly
  const queryParams = new URLSearchParams();
  Object.entries(filters).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => queryParams.append(key, item));
    } else {
      queryParams.append(key, value);
    }
  });

  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/backtestresults/?${queryParams.toString()}`, {
    headers: new Headers({
        'Authorization': `Token ${authToken}`,
    }),
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return response.json();
};

export const useBacktestResults = (filters: Record<string, string | string[]>, isEnabled: boolean) => {
  return useQuery<BacktestResult[], Error>(
    ['backtestResults', filters],
    () => fetchBacktestResults(filters),
    { enabled: isEnabled } // Only run the query if isEnabled is true
  );
};
