import React, { useContext } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import BacktestPage from './pages/BacktestPage';
import AccountInfoPage from './pages/AccountInfoPage';
import { ConfigProvider, Layout, Menu, theme } from 'antd';
import './App.css'; // Make sure to import your App.css or another CSS file for custom styling

import AuthProvider from './AuthProvider';
import { AuthContext } from './AuthContext';
import LoginForm from './LoginForm';

const { Header, Content, Footer } = Layout;

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </QueryClientProvider>
  );
};

const AppRoutes: React.FC = () => {
  const { isLoggedIn, logout } = useContext(AuthContext);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <Layout className="layout">
            <Header>
              <div className="logo" >
                <img src="/bbtclogo.png" alt="BBTC Logo" style={{ maxHeight: '64px' }} />
              </div>
              <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1">
                  <Link to="/backtest">Backtest</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/account-info">Account Info</Link>
                </Menu.Item>
                <Menu.Item key="/logout" style={{ color: 'white' }} onClick={logout}>
                  Logout
               </Menu.Item>
              </Menu>
            </Header>
            <Content style={{ padding: '0 50px' }}>
              <div className="site-layout-content">
                <Routes>
                  <Route path="/" element={isLoggedIn ? <Navigate to="/backtest" /> : <Navigate to="/login" />} />
                  <Route path="/backtest" element={isLoggedIn ? <BacktestPage />: <Navigate to="/login" />} />
                  <Route path="/account-info" element={isLoggedIn ? <AccountInfoPage /> : <Navigate to="/login" />} />
                  <Route path="/login" element={<LoginForm />} />
                </Routes>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>BBTC ©2024 Created by BBTC Team</Footer>
          </Layout>
        </Router>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
