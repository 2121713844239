import React, { useState } from 'react';
import { Button, DatePicker, Form, Select, Row, Col } from 'antd';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

interface Props {
  onFilterChange: (filters: Record<string, string | string[]>) => void;
}

export const BacktestFilter: React.FC<Props> = ({ onFilterChange }) => {
  const [coin, setCoin] = useState<string[]>(['ADA', 'SOL', 'DOGE', 'DOT', 'AVAX']);
  const [selectedModel, setSelectedModel] = useState<string>('Model124');
  const [exchange, setExchange] = useState<string>('Binance');
  const [dateRange, setDateRange] = useState<[any, any] | undefined>(undefined);

  const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
    setDateRange(dates); // Now this accepts any type of input
  };
    
  const handleSearch = () => {
    const filters: Record<string, string | string[]> = {};
    if (coin.length > 0) filters.coin = coin;
    if (selectedModel) filters.model_id = selectedModel;
    if (exchange) filters.exchange = exchange;
    if (dateRange) {
      filters.start_date = dateRange[0].format('YYYY-MM-DD');
      filters.end_date = dateRange[1].format('YYYY-MM-DD');
    }
    
    onFilterChange(filters);
  };

  const defaultStart = dayjs('2022-05-24');
  const defaultEnd = dayjs(); // this creates a dayjs object for the current date

  return (
    <Form style={{ width: '800px', margin: '20px', padding: '20px', border: '1px solid #f0f0f0', borderRadius: '8px' }}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Coins" name="coins">
            <Select
              mode="multiple"
              value={coin}
              onChange={setCoin}
              placeholder="Select coins"
              style={{ width: '700px' }}
              defaultValue={['ADA', 'SOL', 'DOGE', 'DOT', 'AVAX']}>
              {['ADA', 'SOL', 'DOGE', 'DOT', 'AVAX', 'MATIC', 'LTC', 'NEAR', 'UNI', 'BCH',
                'LINK',  'ATOM', 'ALGO', 'ETC', 'HBAR', 'VET', 'MANA', 'EGLD', 'FIL', 'XTZ',
                'ZEC', 'MKR', 'EOS', 'AXS', 'XLM', 'CHZ', 'CRV'].map((c) => (
                <Select.Option key={c} value={c}>{c}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Models" name="models">
            <Select
              defaultValue={selectedModel}
              value={selectedModel}
              onChange={setSelectedModel}
              placeholder="Select model"
              style={{ width: '100%' }}>
              <Select.Option value="Model124">Model 124</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Exchange" name="exchange">
            <Select
              defaultValue={exchange}
              value={exchange}
              onChange={(val) => setExchange(val)}
              style={{ width: '100%' }}>
              <Select.Option value="Binance">Binance</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={16}>
          <Form.Item label="Date Range" name="dateRange">
            <RangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
              format="YYYY-MM-DD"
              defaultValue={[defaultStart, defaultEnd]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'left' }}>
          <Button type="primary" onClick={handleSearch}>
            Get Backtest Results
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
