import React, { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';

import { DatePicker, Typography, Card, Row, Col, Statistic, Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

// Extend dayjs with the plugins
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const { RangePicker } = DatePicker;

interface Order {
  order_id: string;
  product_id: string;
  quantity: string;
  limit_price: string;
  side: string;
  status: string;
  created_time: string;
  average_filled_price: string;
  total_fees: string
  completion_percentage: string;
  filled_value: string;
  filled_size: string;
}

interface Trade {
  direction: string;
  original_total_quantity: string;
  original_limit_price: string;
  final_total_quantity: string;
  hour_price: string;
  actual_average_price: string;
  total_fees: string;
  orders: Order[];
}

interface TradeCycle {
  coin: string;
  start_time: string;
  end_time: string | null;
  trades: Trade[];
}

interface TradeCycleComponentProps {
  tradeCycles: TradeCycle[];
}

const TradeCycleComponent: React.FC<TradeCycleComponentProps> = ({ tradeCycles }) => {
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([null, null]);

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null, dateStrings: [string, string]) => {
    setDateRange(dates || [null, null]);
  };

  const filteredTradeCycles = tradeCycles.filter(cycle => {
    const startTime = dayjs(cycle.start_time);
    const endTime = cycle.end_time ? dayjs(cycle.end_time) : dayjs(); // Use current day if end_time is null
    return (!dateRange[0] || startTime.isSameOrAfter(dateRange[0])) &&
           (!dateRange[1] || endTime.isSameOrBefore(dateRange[1]));
  });

  // Sort tradeCycles by start_time in descending order to show the most recent first
  const sortedTradeCycles = [...filteredTradeCycles].sort((a, b) => {
    return new Date(b.start_time).getTime() - new Date(a.start_time).getTime();
  });

  const tradeColumns: ColumnsType<Trade> = [
    { title: 'Direction', dataIndex: 'direction', key: 'direction' },
    { 
      title: 'Quantity',
      dataIndex: 'final_total_quantity',
      key: 'final_total_quantity',
      render: (text) => parseFloat(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    {
      title: 'Average Price',
      dataIndex: 'actual_average_price',
      key: 'actual_average_price',
      render: (text) => `$${parseFloat(text).toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 5, maximumFractionDigits: 5 }).slice(1)}`
    },
    {
      title: 'Hour Price',
      dataIndex: 'hour_price',
      key: 'hour_price',
      render: (text) => `$${parseFloat(text).toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 5, maximumFractionDigits: 5 }).slice(1)}`
    },
    {
      title: 'Slippage',
      key: 'slippage',
      render: (_, record) => {
        if (record.hour_price === null) {
          return 'N/A';
        }
        const actualAveragePrice = parseFloat(record.actual_average_price);
        const hourPrice = parseFloat(record.hour_price);
        let slippagePercentage = ((actualAveragePrice - hourPrice) / hourPrice) * 100;
        
        // Reverse the slippage percentage for sell orders
        if (record.direction === 'SELL') {
          slippagePercentage = -slippagePercentage;
        }

        return (
          <span>
            {slippagePercentage.toFixed(2)}%
          </span>
        );
      },
    },
    {
      title: 'Amount',
      key: 'amount',
      render: (_, record) => {
        // Calculate $ Amount
        const amount = parseFloat(record.actual_average_price) * parseFloat(record.final_total_quantity);
        // Format as currency
        return `$${amount.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).slice(1)}`;
      },
    },
    {
      title: 'Fee',
      dataIndex: 'total_fees',
      key: 'total_fees',
      render: (text) => `$${parseFloat(text).toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).slice(1)}`,
    },
    {
      title: 'Fee %',
      key: 'percentFees',
      render: (_, record) => {
        // Calculate $ Amount
        const amount = parseFloat(record.actual_average_price) * parseFloat(record.final_total_quantity);
        // Calculate fees as a percentage of $ Amount
        const feesPercentage = (parseFloat(record.total_fees) / amount) * 100;
        // Check for NaN or Infinity due to division by 0 or other errors
        if (isNaN(feesPercentage) || !isFinite(feesPercentage)) {
          return 'N/A'; // Handle error or division by 0
        }
        // Format as percentage
        return `${feesPercentage.toFixed(2)}%`;
      },
    },    
  ];

  const orderColumns: ColumnsType<Order> = [
    { title: 'Product ID', dataIndex: 'product_id', key: 'product_id' },
    { title: 'Side', dataIndex: 'side', key: 'side' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: '$ Amount',
      key: 'amount',
      render: (_, record) => {
        // Calculate $ Amount
        const amount = parseFloat(record.average_filled_price) * parseFloat(record.filled_size);
        // Format as currency
        return `$${amount.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).slice(1)}`;
      },
    },    
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (text) => parseFloat(text).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    {
      title: 'Limit Price',
      dataIndex: 'limit_price',
      key: 'limit_price',
      render: (text) => `$${parseFloat(text).toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 5, maximumFractionDigits: 5 }).slice(1)}`, // Slice to remove the currency symbol added by toLocaleString
    },
    {
      title: 'Avg Fill Price',
      dataIndex: 'average_filled_price',
      key: 'average_filled_price',
      render: (text) => `$${parseFloat(text).toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 5, maximumFractionDigits: 5 }).slice(1)}`,
    },
    {
      title: 'Fee $',
      dataIndex: 'total_fees',
      key: 'total_fees',
      render: (text) => `$${parseFloat(text).toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).slice(1)}`,
    },
    {
      title: 'Fee %',
      key: 'percentFees',
      render: (_, record) => {
        // Calculate $ Amount
        const amount = parseFloat(record.average_filled_price) * parseFloat(record.filled_size);
        // Calculate fees as a percentage of $ Amount
        const feesPercentage = (parseFloat(record.total_fees) / amount) * 100;
        // Check for NaN or Infinity due to division by 0 or other errors
        if (isNaN(feesPercentage) || !isFinite(feesPercentage)) {
          return 'N/A'; // Handle error or division by 0
        }
        // Format as percentage
        return `${feesPercentage.toFixed(2)}%`;
      },
    },    
    { title: 'Created Time', dataIndex: 'created_time', key: 'created_time' },
  ];

  const expandedRowRender = (record: Trade) => {
    return <Table columns={orderColumns} dataSource={record.orders} pagination={false} rowKey="order_id" />;
  };
  
  const calculatePercentageGainLoss = (tradeCycle: TradeCycle) => {
    if (tradeCycle.trades.length === 2) {
      const buyTrade = tradeCycle.trades.find(trade => trade.direction === 'BUY');
      const sellTrade = tradeCycle.trades.find(trade => trade.direction === 'SELL');
      if (buyTrade && sellTrade) {
        const buyPrice = parseFloat(buyTrade.actual_average_price);
        const sellPrice = parseFloat(sellTrade.actual_average_price);
        return ((sellPrice - buyPrice) / buyPrice) * 100;
      }
    }
    return 0;
  };

  // Update all the calculations to use filteredTradeCycles
  const averageGainLoss = useMemo(() => {
    const gains = filteredTradeCycles.map(calculatePercentageGainLoss).filter(value => value !== 0);
    return gains.length ? gains.reduce((acc, value) => acc + value, 0) / gains.length : 0;
  }, [filteredTradeCycles]);

  const calculateHourPercentageGainLoss = (tradeCycle: TradeCycle) => {
    if (tradeCycle.trades.length === 2) {
      const buyTrade = tradeCycle.trades.find(trade => trade.direction === 'BUY');
      const sellTrade = tradeCycle.trades.find(trade => trade.direction === 'SELL');
      if (buyTrade && sellTrade) {
        const buyPrice = parseFloat(buyTrade.hour_price);
        const sellPrice = parseFloat(sellTrade.hour_price);
        return ((sellPrice - buyPrice) / buyPrice) * 100;
      }
    }
    return 0;
  };

  // Calculate the average slippage across all trades
  const averageSlippage = useMemo(() => {
    // Explicitly typing the array after filter to assure TypeScript that it contains no nulls
    const slippages: number[] = filteredTradeCycles.flatMap(cycle => 
      cycle.trades.map(trade => {
        if (trade.hour_price === null || trade.actual_average_price === null) {
          return null;
        }
        const actualAveragePrice = parseFloat(trade.actual_average_price);
        const hourPrice = parseFloat(trade.hour_price);
        let slippagePercentage = ((actualAveragePrice - hourPrice) / hourPrice) * 100;
        
        // Reverse for sell orders
        if (trade.direction === 'SELL') {
          slippagePercentage = -slippagePercentage;
        }

        return slippagePercentage;
      })
    ).filter((slippage): slippage is number => slippage !== null); // This line assures TypeScript the array has no nulls

    if (slippages.length === 0) return 0; // Avoid division by zero

    const totalSlippage = slippages.reduce((acc: number, curr: number) => acc + curr, 0);
    return totalSlippage / slippages.length; // Compute average
  }, [sortedTradeCycles]);

  const averageFeePercentage = useMemo(() => {
    const feesPercentages = filteredTradeCycles.flatMap(cycle => 
      cycle.trades.map(trade => {
        // Calculate total $ Amount for this trade
        const tradeAmount = parseFloat(trade.actual_average_price) * parseFloat(trade.final_total_quantity);
        // Calculate fees as a percentage of $ Amount for this trade
        const tradeFeePercentage = (parseFloat(trade.total_fees) / tradeAmount) * 100;
        return tradeFeePercentage;
      })
    );
  
    // Filter out any NaN or Infinity values to avoid skewing the average
    const validFeesPercentages = feesPercentages.filter(fee => !isNaN(fee) && isFinite(fee));
  
    if (validFeesPercentages.length === 0) return 0; // Avoid division by zero
    
    // Calculate the average fee percentage
    const totalFeePercentage = validFeesPercentages.reduce((acc, curr) => acc + curr, 0);
    const averageFeePercentage = totalFeePercentage / validFeesPercentages.length;
    
    return averageFeePercentage * 2;
  }, [sortedTradeCycles]);

  const averageTotal = averageSlippage + averageFeePercentage;
  
  return (
    <div>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <Typography.Title level={3}>Trade History</Typography.Title>
        <RangePicker
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          style={{ marginBottom: '20px' }}
        />
        <Row gutter={16}>
          <Col span={4}>
            <Card>
              <Statistic title="Number of Trades" value={filteredTradeCycles.length} />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Actual Avg % (No Fees)" value={`${averageGainLoss.toFixed(2)}%`} />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Average Slippage" value={`${averageSlippage.toFixed(2)}%`} />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Average Fees" value={`${averageFeePercentage.toFixed(2)}%`} />
            </Card>
          </Col>
          <Col span={4}>
            <Card>
              <Statistic title="Average Slippage + Fees" value={`${averageTotal.toFixed(2)}%`} />
            </Card>
          </Col>
        </Row>
      </div>
      {sortedTradeCycles.map((cycle, index) => (
        <div key={index} style={{ marginBottom: 20 }}>
          <Typography.Title level={4}>{cycle.coin}</Typography.Title>
          <Typography.Text>
            {new Date(cycle.start_time).toLocaleDateString('en-US', {
                weekday: 'short', // "Mon" for Monday
                year: 'numeric', // "2024"
                month: 'short', // "February"
                day: 'numeric', // "24"
            })} - {cycle.end_time ? new Date(cycle.end_time).toLocaleDateString('en-US', {
                weekday: 'short', // "Mon" for Monday
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            }) : (<b>Current Trade</b>)}
            {cycle.end_time && (
              <span
                style={{
                  //color: calculatePercentageGainLoss(cycle) >= 0 ? '#3f8600' : '#cf1322',
                  marginLeft: '10px',
                  fontWeight: 500,
                }}
              >
                Actual (No Fees): {calculatePercentageGainLoss(cycle).toFixed(2)}%
                Model: {calculateHourPercentageGainLoss(cycle).toFixed(2)}%
                Slippage: {(calculateHourPercentageGainLoss(cycle) - calculatePercentageGainLoss(cycle)).toFixed(2)}%
              </span>
            )}

          </Typography.Text>
          <Table
            size='small'
            columns={tradeColumns}
            dataSource={cycle.trades}
            pagination={false}
            expandable={{ expandedRowRender }}
            rowKey="direction"
          />
        </div>
      ))}
    </div>
  );
};

export default TradeCycleComponent;
