// AuthContext.tsx
import React from 'react';

interface AuthContextInterface {
    isLoggedIn: boolean;
    name: string | null;
    username: string | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
}

// Create a context with default values
export const AuthContext = React.createContext<AuthContextInterface>({
    isLoggedIn: false,
    name: '',
    username: '',
    login: () => Promise.resolve(),
    logout: () => { },
});
