import React, { useState } from 'react';
import { BacktestFilter } from '../components/BacktestFilter';
import { BacktestResultsTable } from '../components/BacktestResultsTable';
import { useQuery } from 'react-query';
import { Alert, Col, Row, Spin, Button, Modal } from 'antd';


const fetchLatestCandleTime = async (): Promise<string> => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/latest-candle-time/`, {
    headers: new Headers({
        'Authorization': `Token ${authToken}`,
    }),
  });
  if (!response.ok) throw new Error('Network response was not ok');

  const data = await response.json();
  return data.latest_candle_time;
};

const triggerUpdate = async () => {
  const authToken = localStorage.getItem('authToken');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const response = await fetch(`${backendUrl}api/update-backtest/`, {
    headers: new Headers({
        'Authorization': `Token ${authToken}`,
    }),
  });
  if (!response.ok) throw new Error('Network response was not ok');
};

export const BacktestPage: React.FC = () => {
  const [filters, setFilters] = useState<Record<string, string | string[]>>({});
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const { data: latestCandleTime, error: latestCandleTimeError, isLoading: isLoadingLatestCandleTime } = useQuery('latestCandleTime', fetchLatestCandleTime);

  const handleFilterChange = (newFilters: Record<string, string | string[]>) => {
    setFilters(newFilters);
    setIsSearchClicked(true); // This state controls the query's execution
  };

  const handleUpdateClick = () => {
    Modal.confirm({
      title: 'Are you sure you want to update?',
      content: 'This process might take a while and should only be initiated once. Please confirm to proceed.',
      onOk() {
        triggerUpdate() // Assuming this is your function that triggers the update
          .then((status) => {
            console.log('Update started:', status);
            Modal.success({
              content: 'Update process has been started successfully!',
            });
          })
          .catch((error) => {
            console.error('Update failed:', error);
            Modal.error({
              title: 'Error',
              content: 'There was an error starting the update process.',
            });
          });
      },
      onCancel() {
        console.log('Update cancelled');
      },
    });
  };  

  return (
    <div>
      <Row>
        <Col style={{ paddingLeft: '20px' }} span={1}>
          <Button size='small' onClick={handleUpdateClick} type="primary">Update</Button>
        </Col>
        <Col style={{ paddingLeft: '40px', paddingTop: '2px' }} span={23}>
          {isLoadingLatestCandleTime ? (
            <Spin size="small" />
          ) : latestCandleTimeError ? (
            <Alert message="Error fetching latest candle time" type="error" showIcon />
          ) : latestCandleTime ? (
            <div>Last Candle Time: {new Date(latestCandleTime).toUTCString()}</div>
          ) : (
            <div>No latest candle time available.</div>
          )}
        </Col>
      </Row>
      <BacktestFilter onFilterChange={handleFilterChange} />
      <BacktestResultsTable filters={filters} isSearchClicked={isSearchClicked} />
    </div>
  );
};

export default BacktestPage;
