import React from "react";
import { Table } from 'antd';
import moment from "moment";
import { BacktestResult } from "../hooks/useBacktestResults";

interface DayOfWeekSummaryMetricsDisplayProps {
  groupedResults: Record<string, BacktestResult[]>;
  initialCapital: number;
  threshold: number;
}


const DayOfWeekSummaryMetricsDisplay: React.FC<DayOfWeekSummaryMetricsDisplayProps> = ({ groupedResults, initialCapital, threshold }) => {
  // Generate data source for the table from groupedResults
  const dataSource = Object.keys(groupedResults).map(day => {
      // Assuming you have some way to extract these metrics or you'll need to compute them here
      const results = groupedResults[day];
      const totalActualPct = results.reduce((acc, curr) => acc + curr.actual_pct, 0);
      const avgActualPct = results.length > 0 ? totalActualPct / results.length : 0;
      const bestResults = results.filter(result => result.isBest && result.avgModel124 > threshold);
      const avgBestActualPct = bestResults.length > 0 ? bestResults.reduce((acc, curr) => acc + curr.actual_pct, 0) / bestResults.length : 0;

      return {
      key: day,
      day,
      avgActualPct: `${(avgActualPct * 100).toFixed(2)}%`,
      avgBestActualPctForThreshold: `${(avgBestActualPct * 100).toFixed(2)}%`,
      avgActualPctDiff: `${((avgBestActualPct - avgActualPct) * 100).toFixed(2)}%`,
      tradeCountForThreshold: bestResults.length,
      };
  }).sort((a, b) => moment(a.day, "dddd").day() - moment(b.day, "dddd").day());

  const columns = [
    {
      title: 'Day of Week',
      dataIndex: 'day',
      key: 'day',
      sorter: (a: any, b: any) => moment(a.day, "dddd").day() - moment(b.day, "dddd").day(),
      render: (text: string) => text,
    },
    {
      title: 'Trade Count',
      dataIndex: 'tradeCountForThreshold',
      key: 'tradeCountForThreshold',
      align: 'right' as const,
      sorter: (a: any, b: any) => a.tradeCountForThreshold - b.tradeCountForThreshold,
    },
    {
      title: 'Average % All Trades',
      dataIndex: 'avgActualPct',
      key: 'avgActualPct',
      align: 'right' as const,
      sorter: (a: any, b: any) => parseFloat(a.avgActualPct.replace('%', '')) - parseFloat(b.avgActualPct.replace('%', '')),
    },
    {
      title: 'Average % Best Trades',
      dataIndex: 'avgBestActualPctForThreshold',
      key: 'avgBestActualPctForThreshold',
      align: 'right' as const,
      sorter: (a: any, b: any) => parseFloat(a.avgBestActualPctForThreshold.replace('%', '')) - parseFloat(b.avgBestActualPctForThreshold.replace('%', '')),
    },
    {
      title: 'Difference % (Best - All)',
      dataIndex: 'avgActualPctDiff',
      key: 'avgActualPctDiff',
      align: 'right' as const,
      sorter: (a: any, b: any) => parseFloat(a.avgActualPctDiff.replace('%', '')) - parseFloat(b.avgActualPctDiff.replace('%', '')),
    },
  ];
  
  return (
    <div>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
};

export default DayOfWeekSummaryMetricsDisplay;
