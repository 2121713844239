import React from 'react';
import { Table } from 'antd';
import { thresholds } from './BacktestResultsTable';

interface SummaryMetrics {
  avgBestActualPctByThreshold: Record<number, number>;
  endingCapitalByThreshold: Record<number, number>;
  tradeCountsByThreshold: Record<number, number>;
  avgRankByThreshold: Record<number, number>;
  avgActualPct: any;
}

interface SummaryMetricsDisplayProps {
  summaryMetrics: SummaryMetrics;
  initialCapital: number;
  numCoins: number;
}

const SummaryMetricsDisplay: React.FC<SummaryMetricsDisplayProps> = ({ summaryMetrics, initialCapital, numCoins }) => {

  const dataSource = thresholds.map((threshold, index) => {
    const endingCapital = summaryMetrics.endingCapitalByThreshold[threshold];
    const percentGain = ((endingCapital - initialCapital) / initialCapital) * 100;
    const tradeCount = summaryMetrics.tradeCountsByThreshold[threshold];
    const avgRank = summaryMetrics.avgRankByThreshold[threshold]; // Get the average rank for this threshold
  
    return {
      key: index,
      threshold: `${(threshold * 100).toFixed(1)}%`,
      avgBestActualPct: (summaryMetrics.avgBestActualPctByThreshold[threshold] * 100).toFixed(2),
      avgRank: avgRank ? avgRank.toFixed(2) : 'N/A', // Format or handle cases where rank might not be available
      endingCapital: (endingCapital - initialCapital).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
      percentGain: percentGain.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      tradeCount,
    };
  });  

  const columns = [
    {
      title: 'Threshold',
      dataIndex: 'threshold',
      key: 'threshold',
      align: 'right' as const,
    },
    {
      title: '# Trades',
      dataIndex: 'tradeCount',
      key: 'tradeCount',
      align: 'right' as const,
    },
    {
      title: 'Mean Best Coin',
      dataIndex: 'avgBestActualPct',
      key: 'avgBestActualPct',
      render: (text: string) => `${text}%`,
      align: 'right' as const,
    },
    {
      title: 'Capital Change ($30K)',
      dataIndex: 'endingCapital',
      key: 'endingCapital',
      align: 'right' as const,
    },
    {
      title: '% Change',
      dataIndex: 'percentGain',
      key: 'percentGain',
      render: (text: string) => `${text}%`,
      align: 'right' as const,
    },
    {
      title: 'Avg Rank',
      dataIndex: 'avgRank',
      key: 'avgRank',
      render: (avgRank: any, record: any) => {
        const avgRankNumber = Number(avgRank);
        const rankPercentage = ((numCoins - avgRankNumber) / (numCoins - 1)) * 100;
        return `${avgRank} (${rankPercentage.toFixed(0)}%)`;
      },
      align: 'right' as const,
    }
  ];

  return (
    <div style={{ margin: '20px' }}>
      <Table size='small' style={{ width: '700px' }} dataSource={dataSource} columns={columns} pagination={false} />
      <h4>Mean All Coins: {`${(summaryMetrics.avgActualPct * 100).toFixed(2)}%`}</h4>
    </div>
  );
};

export default SummaryMetricsDisplay;
