// components/BacktestResultsTable.tsx
import React, { useState } from 'react';
import { Col, Input, Row, Select, Space, Table } from 'antd';
import { BacktestResult, useBacktestResults } from '../hooks/useBacktestResults';
import SummaryMetricsDisplay from './SummaryMetricsDisplay';
import moment from 'moment';
import MonthlySummaryMetricsDisplay from './MonthlySummaryMetricsDisplay';
import CapitalChangeChart from './CapitalChangeChart';
import DayOfWeekResultsDisplay from './DayOfWeekResultsDisplay';

export const thresholds = [0.0, 0.015, 0.02, 0.025, 0.028, 0.03, 0.04, 0.05];

type GroupByOption = "quarter" | "month" | "week" | "year";

interface GroupedResults {
  [key: string]: BacktestResult[];
}

interface Props {
  filters: Record<string, string | string[]>;
  isSearchClicked: boolean;
}

export const BacktestResultsTable: React.FC<Props> = ({ filters, isSearchClicked }) => {
  const { data: results, isLoading, error } = useBacktestResults(filters, isSearchClicked);
  const [feeSlippagePercent, setFeeSlippagePercent] = useState(0.2); // Initial fee/slippage percentage
  const [initialCapital, setInitialCapital] = useState(30000); // Initial capital, default to 30,000
  const [threshold, setThreshold] = useState(0.028); // Initial threshold, default to 2.8%
  const [groupBy, setGroupBy] = useState<GroupByOption>('month');


  // Handler to update fee/slippage percent based on user input
  const handleFeeSlippageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      setFeeSlippagePercent(value);
    }
  };
  
  // Handler for initial capital input change
  const handleInitialCapitalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10);
    if (!isNaN(value)) {
      setInitialCapital(value);
    }
  };

  const handleThresholdChange = (value: number) => {
    setThreshold(value);
  };  

  const processedResults = React.useMemo(() => {

    const adjustedResults = results?.map(result => {
      const avgModel124 = (result.model1_mean + result.model2_mean + result.model4_mean) / 3;
      //const avgModel124 = (result.model1_mean) / 1;

      const isAboveThreshold = avgModel124 > threshold; // Check if the average is above 1.5%
      return {
        ...result,
        actual_pct: result.actual_pct - (feeSlippagePercent / 100),
        avgModel124Percent: avgModel124,
        isAboveThreshold, // Add this to each result
      };
    });
      
    // Define groupedByTime with an explicit type to store the adjusted results
    const groupedByTime: Record<string, BacktestResult[]> = {};
    
    // Use adjustedResults for further processing
    adjustedResults?.forEach((result: BacktestResult) => {
      const avgModel124 = (result.model1_mean + result.model2_mean + result.model4_mean) / 3;
      //const avgModel124 = (result.model1_mean) / 1;

      result.avgModel124 = avgModel124;
  
      if (!groupedByTime[result.last_candle_time]) {
        groupedByTime[result.last_candle_time] = [result];
      } else {
        groupedByTime[result.last_candle_time].push(result);
      }
    });
  
    // Process to mark the best coin for each day
    Object.keys(groupedByTime).forEach((time) => {
      let bestAvgModel124 = -Infinity;
      let bestCoin: BacktestResult | null = null;
  
      groupedByTime[time].forEach((coinData) => {
        if (coinData.avgModel124 > bestAvgModel124) {
          bestAvgModel124 = coinData.avgModel124;
          bestCoin = coinData;
        }
      });
  
      // Mark the best coin
      groupedByTime[time].forEach((coinData) => {
        coinData.isBest = (coinData === bestCoin);
      });
    });

    const groupedByTimeWithRank: Record<string, BacktestResult[]> = {};

    Object.keys(groupedByTime).forEach((time) => {
      const coins = groupedByTime[time];
      
      // Sort coins by actual_pct descending
      coins.sort((a, b) => b.actual_pct - a.actual_pct);
      
      // Assign rank based on sorted position
      coins.forEach((coin, index) => {
        coin.dailyRank = index + 1; // Assign rank starting from 1
      });

      groupedByTimeWithRank[time] = coins; // Store the coins with rank info
    });

    // Flatten the results back into an array with ranks
    const resultsWithRank = Object.values(groupedByTimeWithRank).flat();
    return resultsWithRank;
  }, [results, feeSlippagePercent]);
    
  const calculateEndingCapital = (results: BacktestResult[], initialCapital: number, threshold: number) => {
    let capital = initialCapital;
    results.forEach(result => {
      if (result.isBest && result.avgModel124 > threshold) {
        capital += capital * result.actual_pct;
      }
    });
    return capital;
  };

  const calculateSummaryMetrics = (results: BacktestResult[]) => {
    const totalActualPct = results.reduce((acc, result) => acc + result.actual_pct, 0);
    const avgActualPct = results.length > 0 ? totalActualPct / results.length : 0;
  
    const bestResults = results.filter(result => result.isBest);
  
    const avgBestActualPct = bestResults.length > 0 
      ? bestResults.reduce((acc, result) => acc + result.actual_pct, 0) / bestResults.length 
      : 0;

    const avgBestActualPctByThreshold: any = {};
    const endingCapitalByThreshold: any = {};
    const tradeCountsByThreshold: any = {};
  
    const avgRankByThreshold: any = {};

    thresholds.forEach(threshold => {
      const bestResultsAboveThreshold = results.filter(result => result.isBest && result.avgModel124 > threshold);
      
      // Calculate the average rank for the best results above the threshold
      const avgRank = bestResultsAboveThreshold.length > 0
        ? bestResultsAboveThreshold.reduce((acc, result) => acc + result.dailyRank, 0) / bestResultsAboveThreshold.length
        : null; // null or any default value if no best results above the threshold
      
      avgRankByThreshold[threshold] = avgRank;
      
      // Continue with existing calculations...
      const avgBestActualPctAboveThreshold = bestResultsAboveThreshold.length > 0 
        ? bestResultsAboveThreshold.reduce((acc, result) => acc + result.actual_pct, 0) / bestResultsAboveThreshold.length 
        : 0;
      
      avgBestActualPctByThreshold[threshold] = avgBestActualPctAboveThreshold;
      tradeCountsByThreshold[threshold] = bestResultsAboveThreshold.length;
      endingCapitalByThreshold[threshold] = calculateEndingCapital(results, initialCapital, threshold);
    });

    // Return the updated metrics including avgRankByThreshold
    return {
      avgActualPct,
      avgBestActualPct,
      avgBestActualPctByThreshold,
      endingCapitalByThreshold,
      tradeCountsByThreshold,
      avgRankByThreshold, // Include the average rank by threshold in the returned object
    };
  };
  
  // In your component where the table is rendered
  const summaryMetrics = calculateSummaryMetrics(processedResults);

  const groupResultsByTimePeriod = (results: BacktestResult[]): GroupedResults => {    
    const groupedByTimePeriod: GroupedResults = {};

    results.forEach(result => {
      let timePeriodKey: string;

      switch (groupBy) {
        case "quarter":
          timePeriodKey = moment(result.last_candle_time).format("YYYY-[Q]Q");
          break;
        case "month":
          timePeriodKey = moment(result.last_candle_time).format("YYYY-MM");
          break;
        case "week":
          timePeriodKey = moment(result.last_candle_time).format("YYYY-[W]WW");
          break;
        case "year":
          timePeriodKey = moment(result.last_candle_time).format("YYYY");
          break;
        default:
          timePeriodKey = moment(result.last_candle_time).format("YYYY-MM");
      }

      if (!groupedByTimePeriod[timePeriodKey]) {
        groupedByTimePeriod[timePeriodKey] = [result];
      } else {
        groupedByTimePeriod[timePeriodKey].push(result);
      }
    });

    return groupedByTimePeriod;
  };

  const calculateMonthlySummaryMetrics = (groupedByMonth: any) => {
    const monthlySummary: any = {};
  
    Object.keys(groupedByMonth).forEach(monthYear => {
      const results = groupedByMonth[monthYear];
      // Use your existing logic to calculate summary metrics for `results`
      const metrics = calculateSummaryMetrics(results);
      monthlySummary[monthYear] = metrics;
    });
  
    return monthlySummary;
  };
  
  // Calculate the grouped results by month
  const groupedResults = groupResultsByTimePeriod(processedResults);

  // Calculate monthly summary metrics
  const monthlySummaryMetrics = calculateMonthlySummaryMetrics(groupedResults);

  const calculateCapitalOverTime = (results: BacktestResult[], initialCapital: number, threshold: number) => {
    let capital = initialCapital;
    
    // Sort results by last_candle_time in ascending order
    const sortedResults = [...results].sort((a, b) => new Date(a.last_candle_time).getTime() - new Date(b.last_candle_time).getTime());

    // Filter results to only include trades that were made
    const tradesMade = sortedResults.filter(result => result.isBest && result.avgModel124 > threshold);
  
    const capitalOverTime = tradesMade.map(result => {
      capital += capital * result.actual_pct;
      return {
        time: result.last_candle_time,
        capital: capital,
      };
    });
    return capitalOverTime;
  };

  const tradeDataForChart = calculateCapitalOverTime(processedResults, initialCapital, threshold);

  // Define filters for the coin column
  const coinFilters = ['ADA', 'SOL', 'DOGE', 'DOT', 'AVAX', 'ATOM', 'VET', 'ETC', 'UNI', 'EOS', 'XLM', 'CHZ', 'CRV'].map(coin => ({
    text: coin,
    value: coin + 'USDT', // Assuming your coin data includes 'USDT'
  }));

  const groupResultsByDayOfWeek = (results: BacktestResult[]): Record<string, BacktestResult[]> => {
    const groupedByDayOfWeek: Record<string, BacktestResult[]> = {};
  
    results.forEach(result => {
      const dayOfWeek = moment(result.last_candle_time).format('dddd'); // 'Monday', 'Tuesday', etc.
      if (!groupedByDayOfWeek[dayOfWeek]) {
        groupedByDayOfWeek[dayOfWeek] = [result];
      } else {
        groupedByDayOfWeek[dayOfWeek].push(result);
      }
    });
  
    return groupedByDayOfWeek;
  };

  const groupedResultsByDOW = groupResultsByDayOfWeek(processedResults);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (_: any, __: any, index: any) => index + 1,
    },
    {
      title: 'Last Candle Time',
      dataIndex: 'last_candle_time',
      key: 'last_candle_time',
      sorter: (a: any, b: any) => new Date(a.last_candle_time).getTime() - new Date(b.last_candle_time).getTime(),
      render: (text: any) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Target Time',
      dataIndex: 'target_time',
      key: 'target_time',
      sorter: (a: any, b: any) => new Date(a.target_time).getTime() - new Date(b.target_time).getTime(),
      render: (text: any) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Coin',
      dataIndex: 'coin',
      key: 'coin',
      filters: coinFilters,
      onFilter: (value: any, record: any) => record.coin.includes(value),
      align: 'right' as const,
    },
    {
      title: 'Actual %',
      dataIndex: 'actual_pct',
      key: 'actual_pct',
      sorter: (a: any, b: any) => a.actual_pct - b.actual_pct,
      render: (text: number) => `${(text * 100).toFixed(2)}%`,
      align: 'right' as const,
    },
    {
      title: 'Model124 %',
      dataIndex: 'avgModel124Percent',
      key: 'avg_models_124',
      sorter: (a: any, b: any) => a.avgModel124Percent - b.avgModel124Percent,
      render: (value: any, record: any) => `${(record.avgModel124Percent * 100).toFixed(2)}%`,
      align: 'right' as const,
      filters: [
        { text: 'Above 2.8%', value: 'above' },
        { text: 'Below 2.8%', value: 'below' }
      ],
      onFilter: (value: any, record: any) => {
        return value === 'above' ? record.isAboveThreshold : !record.isAboveThreshold;
      }
    },
    {
      title: 'Best',
      dataIndex: 'isBest',
      key: 'isBest',
      render: (text: string) => text ? "Yes" : "No",
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      onFilter: (value: any, record: any) => record.isBest === value,
      align: 'right' as const,
    },
    {
      title: 'Daily Rank',
      dataIndex: 'dailyRank',
      key: 'dailyRank',
      render: (rank: number) => rank,
      sorter: (a: any, b: any) => a.dailyRank - b.dailyRank,
      align: 'right' as const,
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Row>
      <Col>
        <Row gutter={16}>
          <Col>
            <Input
              addonBefore="Per Trade Fee/Slippage %"
              type="number"
              min={0}
              max={1}
              step={0.1}
              value={feeSlippagePercent}
              onChange={handleFeeSlippageChange}
              style={{ width: '250px', marginLeft: '20px' }}
            />
          </Col>
          <Col>
            <Input
              addonBefore="Initial Capital"
              type="number"
              min={10000} // Minimum capital
              max={1000000} // Maximum capital, adjust as needed
              step={10000} // Step size of 10K
              value={initialCapital}
              onChange={handleInitialCapitalChange}
              style={{ width: '250px' }}
            />
          </Col>
          <Col>
            <Select
              value={threshold}
              onChange={handleThresholdChange}
            >
              {thresholds.map((value) => (
                <Select.Option key={value} value={value}>
                  {`${(value * 100).toFixed(2)}%`}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              value={groupBy}
              onChange={setGroupBy}
            >
              <Select.Option value="week">Week</Select.Option>
              <Select.Option value="month">Month</Select.Option>
              <Select.Option value="quarter">Quarter</Select.Option>
              <Select.Option value="year">Year</Select.Option>
            </Select>
          </Col>
        </Row>
        {summaryMetrics && <SummaryMetricsDisplay summaryMetrics={summaryMetrics} initialCapital={initialCapital} numCoins={filters.coin?.length} />}
        
        {/* Monthly Summary Metrics Display */}
        {monthlySummaryMetrics && <MonthlySummaryMetricsDisplay monthlySummary={monthlySummaryMetrics} initialCapital={initialCapital} threshold={threshold} />}
      
        {/* Capital Change Chart */}
        <CapitalChangeChart tradeData={tradeDataForChart} />

        {groupedResultsByDOW && <DayOfWeekResultsDisplay groupedResults={groupedResultsByDOW} initialCapital={initialCapital} threshold={threshold} />}
        
        <Table
          size="small"
          dataSource={processedResults}
          columns={columns}
          rowKey="id"
          style={{ width: '1200px', margin: '20px' }}
        />
      </Col>
    </Row>
  );
};
